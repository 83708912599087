import React from "react"
import { Redirect, Route, Router, Switch } from "react-router-dom"
import "./all.scss"
import history from "./history"
import { AuthContextProvider, useAuth } from "./auth"
import Navigation from "./lib/Navigation"
import Footer from "./lib/Footer"

import Home from "./pages/Home"
import Projet from "./pages/Projet"
import Organismes from "./pages/Organismes"
import Badges from "./pages/Badges"
import Derniers from "./pages/Derniers"

import loadable from "@loadable/component"
const Login = loadable(() => import(/* webpackChunkName: "dashboard" */ "./auth/Login"))
const Logout = loadable(() => import(/* webpackChunkName: "dashboard" */ "./auth/Logout"))
const TokenInfo = loadable(() => import(/* webpackChunkName: "dashboard" */ "./auth/TokenInfo"))
const Dashboard = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/Dashboard"))
const Password = loadable(() => import(/* webpackChunkName: "dashboard" */ "./account/Password"))
const Recover = loadable(() => import(/* webpackChunkName: "dashboard" */ "./account/Recover"))
const Retrieve = loadable(() => import(/* webpackChunkName: "dashboard" */ "./account/Retrieve"))
const AccountList = loadable(() => import(/* webpackChunkName: "dashboard" */ "./account/AccountList"))
const AccountEdit = loadable(() => import(/* webpackChunkName: "dashboard" */ "./account/AccountEdit"))
const OrgaList = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/OrgaList"))
const OrgaEdit = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/OrgaEdit"))
const OBFCert = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/OBFCert"))
const OBFImport = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/OBFImport"))
const ContentEdit = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/ContentEdit"))
const BadgeList = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/BadgeList"))
const BadgeEdit = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/BadgeEdit"))
const ContactEmail = loadable(() => import(/* webpackChunkName: "dashboard" */ "./dashboard/ContactEmail"))

history.listen(location => {
    // Scroll sur le hash
    const hash = location.hash
    if (hash === "") {
        window.scrollTo(0, 0)
    } else {
        setTimeout(() => {
            const id = hash.replace("#", "")
            const element = document.getElementById(id)
            if (element) element.scrollIntoView()
        }, 0)
    }
})

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [logged] = useAuth()

    return (
        <Route
            {...rest}
            render={props => {
                if (logged) return <Component {...props} />
                else return <Redirect to={"/login?next=" + encodeURIComponent(props.location.pathname)} />
            }}
        />
    )
}

function App() {
    return (
        <AuthContextProvider>
            <Router history={history}>
                <Navigation />
                <div id="main-content" className="container">
                    <div className="content mb-6">
                        <Switch>
                            <Route exact path="/projet" component={Projet} />
                            <Route exact path="/organismes" component={Organismes} />
                            <Route exact path="/badges" component={Badges} />
                            <Route exact path="/derniers" component={Derniers} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/logout" component={Logout} />
                            <Route exact path="/token-info" component={TokenInfo} />
                            <Route exact path="/account/retrieve/:token" component={Retrieve} />
                            <Route exact path="/account/recover" component={Recover} />
                            <PrivateRoute exact path="/account/password" component={Password} />
                            <PrivateRoute exact path="/dashboard/account/list" component={AccountList} />
                            <PrivateRoute exact path="/dashboard/account/create" component={AccountEdit} />
                            <PrivateRoute exact path="/dashboard/account/edit/:id" component={AccountEdit} />
                            <PrivateRoute exact path="/dashboard/orga/list" component={OrgaList} />
                            <PrivateRoute exact path="/dashboard/orga/edit/:id" component={OrgaEdit} />
                            <PrivateRoute exact path="/dashboard/orga/create" component={OrgaEdit} />
                            <PrivateRoute exact path="/dashboard/content/edit/:slug" component={ContentEdit} />
                            <PrivateRoute exact path="/dashboard" component={Dashboard} />
                            <PrivateRoute exact path="/dashboard/obf-cert" component={OBFCert} />
                            <PrivateRoute exact path="/dashboard/obf-import" component={OBFImport} />
                            <PrivateRoute exact path="/dashboard/badge/list" component={BadgeList} />
                            <PrivateRoute exact path="/dashboard/badge/list/:orga" component={BadgeList} />
                            <PrivateRoute exact path="/dashboard/badge/edit/:id" component={BadgeEdit} />
                            <PrivateRoute exact path="/dashboard/contact" component={ContactEmail} />
                            <Route exact path="/" component={Home} />
                            {/* catchall */}
                            <Route
                                path="*"
                                render={() => {
                                    window.scrollTo(0, 0)
                                    return <h2 className="title">Not found</h2>
                                }}
                            />
                        </Switch>
                    </div>
                </div>
                <Footer />
            </Router>
        </AuthContextProvider>
    )
}

export default App
