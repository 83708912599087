import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import useSiteData from "../lib/SiteData"

const Badges = () => {
    const siteData = useSiteData()
    const [items, setItems] = useState(null)
    const { hash } = useLocation()

    useEffect(() => {
        document.title = "Badges"
        fetch("/api/badge/list")
            .then(r => r.json())
            .then(data => {
                if (data.ok) {
                    setItems(data.items)
                    if (hash === "") {
                        window.scrollTo(0, 0)
                    } else {
                        setTimeout(() => {
                            const id = hash.replace("#", "")
                            const element = document.getElementById(id)
                            if (element) element.scrollIntoView()
                        }, 0)
                    }
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <h1 className="title">
                <span className="title-inner">Tous les badges</span>
            </h1>
            {siteData.orgas && siteData.orgas.length
                ? siteData.orgas.map(orga => (
                      <div key={orga.id} className="block">
                          <div id={orga.id}></div>
                          <br />
                          <h2 className="subtitle orga">{orga.label}</h2>
                          {items && items[orga.id] ? (
                              items[orga.id].map(badge => (
                                  <div key={badge.id} className="mb-6" style={{ display: "flex" }}>
                                      <div style={{ display: "flex-start", margin: "0 2em 0 0" }}>
                                          <figure style={{ width: "100px", height: "100px", margin: "0" }}>
                                              {badge.image ? <img src={badge.image} alt="" /> : null}
                                          </figure>
                                      </div>
                                      <div style={{ display: "flex-end" }}>
                                          <h3 className="subtitle">{badge.name}</h3>
                                          <div
                                              style={{ whiteSpace: "pre-wrap" }}
                                              dangerouslySetInnerHTML={{ __html: badge.description }}></div>
                                          {badge.earnable_id ? (
                                              <div className="mt-1">
                                                  <a
                                                      href={`https://openbadgefactory.com/c/earnablebadge/${badge.earnable_id}/apply`}
                                                      target="_blank"
                                                      rel="noopener noreferrer">
                                                      <em>Obtenez ce badge</em>
                                                  </a>
                                              </div>
                                          ) : null}
                                      </div>
                                  </div>
                              ))
                          ) : (
                              <>
                                  <br />
                                  <br />
                              </>
                          )}
                      </div>
                  ))
                : null}
        </>
    )
}

export default Badges
