import React, { useEffect, useState } from "react"
import ContactForm from "../lib/ContactForm"

const Projet = () => {
    const [body, setBody] = useState("")

    useEffect(() => {
        document.title = "Projet"
        fetch("/api/content/projet")
            .then(r => r.json())
            .then(data => {
                setBody(data.body)
            })
    }, [])

    return (
        <>
            <h1 className="title">
                <div className="title-inner">Projet</div>
            </h1>
            <div dangerouslySetInnerHTML={{ __html: body }}></div>
            <h1 className="title">
                <div className="title-inner">Nous contacter</div>
            </h1>
            <ContactForm origin="Page projet" />
        </>
    )
}

export default Projet
