import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import ContactForm from "../lib/ContactForm"

const Organismes = () => {
    const [orgas, setOrgas] = useState(null)
    const { hash } = useLocation()

    useEffect(() => {
        document.title = "Organismes de délivrance"
        fetch("/api/orga/list")
            .then(r => r.json())
            .then(data => {
                if (data.items) {
                    setOrgas(data.items)
                    if (hash === "") {
                        window.scrollTo(0, 0)
                    } else {
                        setTimeout(() => {
                            const id = hash.replace("#", "")
                            const element = document.getElementById(id)
                            if (element) element.scrollIntoView()
                        }, 0)
                    }
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <h1 className="title">
                <div className="title-inner">Organismes de délivrance</div>
            </h1>
            {orgas && orgas.length
                ? orgas.map(item => (
                      <div key={item.id} className="block">
                          <div id={item.id}></div>
                          <br />
                          <h2 className="subtitle orga">{item.label}</h2>
                          <div className="columns">
                              <div className="column is-2" style={{ minWidth: "270px" }}>
                                  <figure style={{ width: "270px", height: "120px", margin: "0" }}>
                                      {item.artwork ? <img src={item.artwork} alt={item.label} /> : null}
                                  </figure>
                              </div>
                              <div className="column is-8" style={{ margin: "0 2em" }}>
                                  <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                  {item.badges_text ? (
                                      <Link className="badges-link" to={`/badges#${item.id}`}>
                                          {item.badges_text}
                                      </Link>
                                  ) : null}
                              </div>
                          </div>
                      </div>
                  ))
                : null}
            <h1 className="title">
                <div className="title-inner">
                    Vous souhaitez devenir organisme de délivrance de badges,
                    <br />
                    contactez-nous :
                </div>
            </h1>
            <ContactForm origin="Page organismes" />
        </>
    )
}

export default Organismes
