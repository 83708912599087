import React, { useRef, useState } from "react"

const ContactForm = ({ origin }) => {
    const form = useRef()
    const [formData, setFormData] = useState({
        nom: "",
        email: "",
        sujet: "",
        message: ""
    })
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const [result, setResult] = useState("")

    const handleChange = evt => {
        setFormData({ ...formData, [evt.target.name]: evt.target.value })
    }

    const handleSubmit = evt => {
        evt.preventDefault()
        setError(false)
        fetch("/api/contact", { method: "POST", body: JSON.stringify({ ...formData, origin: origin }) })
            .then(r => r.json())
            .then(data => {
                if (data.ok) setResult(data.msg)
                else {
                    setError(true)
                    setMessage(data.msg)
                }
            })
    }

    return result ? (
        <div className="notification">{result}</div>
    ) : (
        <>
            {message && <div className={`notification${error ? " is-danger" : ""}`}>{message}</div>}
            <form ref={form} onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label" htmlFor="nom">
                        Votre nom
                    </label>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input
                                    id="nom"
                                    name="nom"
                                    className="input"
                                    value={formData.nom || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label" htmlFor="email">
                        Votre e-mail
                    </label>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input
                                    id="email"
                                    name="email"
                                    className="input"
                                    value={formData.email || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label" htmlFor="sujet">
                        Sujet
                    </label>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <input
                                    id="sujet"
                                    name="sujet"
                                    className="input"
                                    value={formData.sujet || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label" htmlFor="message">
                        Votre message
                    </label>
                    <div className="field-body">
                        <div className="field">
                            <div className="control">
                                <textarea
                                    id="message"
                                    name="message"
                                    className="textarea"
                                    rows="6"
                                    value={formData.message || ""}
                                    onChange={handleChange}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <button type="submit" className="button is-link">
                            Envoyer
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ContactForm
