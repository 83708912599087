import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons"
import { useAuth } from "../auth"

const Footer = () => {
    const [logged] = useAuth()

    return (
        <footer className="footer">
            <div className="container level">
                <div className="level-left">
                    &copy; 2021 &thinsp; <a href="https://www.asso-bug.org/">Bug</a> &thinsp; tous droits réservés
                </div>
                <div className="level-right">
                    {logged ? (
                        <>
                            <Link className="navbar-item" to="/dashboard">
                                <FontAwesomeIcon icon={faCog} />
                            </Link>
                            <Link className="navbar-item" to="/logout">
                                <FontAwesomeIcon icon={faPowerOff} />
                            </Link>
                        </>
                    ) : (
                        <Link className="navbar-item" to="/login">
                            <FontAwesomeIcon icon={faUser} />
                        </Link>
                    )}
                </div>
            </div>
        </footer>
    )
}

export default Footer
