import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import svgBadges from "../graphix/badges-min.svg"
import svgDerniers from "../graphix/derniers-min.svg"
import svgOrganismes from "../graphix/organismes-min.svg"
import svgBug from "../graphix/bug-logo-min.svg"

const Home = () => {
    const [body, setBody] = useState("")
    const [encart, setEncart] = useState("")

    useEffect(() => {
        document.title = "Badges.bzh"
        fetch("/api/content/index")
            .then(r => r.json())
            .then(data => {
                setBody(data.body)
                setEncart(data.encart)
            })
    }, [])

    return (
        <>
            <div
                className="columns"
                style={{ background: "url('/static/banner.jpg') center no-repeat", padding: "1em" }}>
                <div className="column is-6 is-offset-3 has-text-centered my-6" style={{ color: "#FFFFFF" }}>
                    <img src={svgBug} alt="BUG" style={{ height: "130px" }} />
                    <div className="mt-2" dangerouslySetInnerHTML={{ __html: body }}></div>
                </div>
            </div>
            <div className="mt-6" style={{ textAlign: "center", width: "100%" }}>
                <div className="splash-link">
                    <div className="splash-link-inner">
                        <Link to="/badges">
                            <img src={svgBadges} style={{ width: "150px", height: "150px" }} alt="" />
                            <h2>Tous les badges</h2>
                        </Link>
                    </div>
                </div>
                <div className="splash-link">
                    <div className="splash-link-inner">
                        <Link to="/derniers">
                            <img src={svgDerniers} style={{ width: "150px", height: "150px" }} alt="" />
                            <h2>Derniers badges</h2>
                        </Link>
                    </div>
                </div>
                <div className="splash-link">
                    <div className="splash-link-inner">
                        <Link to="/organismes">
                            <img src={svgOrganismes} style={{ width: "150px", height: "150px" }} alt="" />
                            <h2>Organismes de délivrance</h2>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="columns mt-4">
                <div className="column is-half" style={{ backgroundColor: "#F5F5F5", padding: "4em" }}>
                    <div dangerouslySetInnerHTML={{ __html: encart }}></div>
                </div>
                <div
                    className="column is-half"
                    style={{ background: "url('/static/encart.jpg') center/100% no-repeat", padding: "4rem" }}></div>
            </div>
        </>
    )
}

export default Home
