import React, { useEffect, useState } from "react"

const Derniers = () => {
    const [items, setItems] = useState(null)

    useEffect(() => {
        fetch("/api/badge/derniers")
            .then(r => r.json())
            .then(data => {
                if (data.items) setItems(data.items)
            })
    }, [])

    return (
        <>
            <h1 className="title">
                <span className="title-inner">Derniers badges</span>
            </h1>
            {items && items.length
                ? items.map(badge => (
                      <div key={badge.id} className="mb-6" style={{ display: "flex" }}>
                          <div style={{ display: "flex-start", margin: "0 2em 0 0" }}>
                              <figure style={{ width: "100px", height: "100px", margin: "0" }}>
                                  {badge.image ? <img src={badge.image} alt="" /> : null}
                              </figure>
                          </div>
                          <div style={{ display: "flex-end" }}>
                              <h3 className="subtitle">{badge.name}</h3>
                              <div
                                  style={{ whiteSpace: "pre-wrap" }}
                                  dangerouslySetInnerHTML={{ __html: badge.description }}></div>
                              {badge.earnable_id ? (
                                  <div className="mt-1">
                                      <a
                                          href={`https://openbadgefactory.com/c/earnablebadge/${badge.earnable_id}/apply`}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          <em>Obtenez ce badge</em>
                                      </a>
                                  </div>
                              ) : null}
                          </div>
                      </div>
                  ))
                : null}
        </>
    )
}

export default Derniers
