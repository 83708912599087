import { useEffect, useState } from "react"

const cache = {}

const useSiteData = () => {
    const [data, setData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            if (cache.current) {
                setData(cache.current)
            } else {
                const response = await fetch("/api/sitedata")
                const data = await response.json()
                cache.current = data
                setData(data)
            }
        }
        fetchData()
    }, [])

    return data
}

export default useSiteData
