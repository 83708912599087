import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { useAuth } from "../auth"
import useSiteData from "../lib/SiteData"

const Navigation = () => {
    const [logged] = useAuth()
    const siteData = useSiteData()
    const history = useHistory()

    useEffect(() => {
        history.listen(() => {
            const toggle = document.getElementById("nav-toggle-state")
            if (toggle) toggle.checked = false
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <nav className="navbar is-fixed-top">
            <div className="container">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        <strong>Badges.bzh</strong>
                    </Link>
                    {logged ? (
                        <Link className="navbar-item" to="/dashboard">
                            <FontAwesomeIcon icon={faCog} />
                        </Link>
                    ) : null}
                    <label
                        role="button"
                        className="navbar-burger burger"
                        aria-label="menu"
                        aria-expanded="false"
                        htmlFor="nav-toggle-state">
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                        <span aria-hidden="true" />
                    </label>
                </div>

                <input type="checkbox" id="nav-toggle-state" />
                <div className="navbar-menu">
                    <div className="navbar-end">
                        <Link className="navbar-item" to="/">
                            Accueil
                        </Link>
                        <Link className="navbar-item" to="/projet">
                            Projet
                        </Link>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/organismes" onClick={evt => evt.target.blur()}>
                                Organismes
                            </Link>
                            {siteData.orgas && siteData.orgas.length ? (
                                <div className="navbar-dropdown">
                                    {siteData.orgas.map(item => (
                                        <Link
                                            key={item.id}
                                            to={`/organismes#${item.id}`}
                                            className="navbar-item"
                                            onClick={evt => evt.target.blur()}>
                                            {item.label}
                                        </Link>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <Link className="navbar-link" to="/badges" onClick={evt => evt.target.blur()}>
                                Badges
                            </Link>
                            {siteData.orgas && siteData.orgas.length ? (
                                <div className="navbar-dropdown">
                                    {siteData.orgas.map(item => (
                                        <Link
                                            key={item.id}
                                            to={`/badges#${item.id}`}
                                            className="navbar-item"
                                            onClick={evt => evt.target.blur()}>
                                            {item.label}
                                        </Link>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navigation
